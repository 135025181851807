import { FilterTypes } from '../../enum/FilterTypes';

export const Filters = [{
    type: FilterTypes.enum.INPUT,
    param: ['title', 'description'],
    label: 'lang.shared.searchPublication',
    icon: 'fa-search'
}];

export const ReferencesFilters = [{
    type: FilterTypes.enum.INPUT,
    param: ['reference', 'description'],
    label: 'lang.shared.searchReferences',
    icon: 'fa-search'
}];