import { App } from "../../domain/App";
import { Address } from "../../domain/entities/inscriptionStructure/Address";
import { ContactData } from "../../domain/entities/inscriptionStructure/ContactData";
import { ProcessType } from "../../domain/enum/ProcessType";
import { Inscription } from "../../domain/entities/inscription/Inscription";
import * as  axios from "../../node_modules/axios/dist/axios";
import * as SecurityService from "../../application/services/SecurityService";

let app = new App();
var mgr = new SecurityService.default();
let urlServices = app.requestTo + 'api/';

axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 422) {
        mgr.signOut();
    }
    return Promise.reject(error);
});

// BOEP
export function urlBoep(): string {
    return urlServices + "boep";
}
export function urlDocuentsId(boepId: string) {
    return urlServices + "boep/" + boepId + "/files";
}

// PROCESS
export function urlInscriptionStructure(processId: string, isPreview: boolean = false) {
    return urlServices + "processes/" + processId + "/inscriptionStructure" + "?isPreview=" + isPreview;
}
export function urlMainFileByIdProcess(processId: string) {
    return urlServices + "processes/" + processId + "/inscriptionFile";
}
export function urlDocuentsIdCall(callId: string) {
    return urlServices + "processes/" + callId + "/files";
}

// CALL
export function urlProcess(processType: number) {
    return urlServices + ProcessType.urlString[processType];
}
export function urlProcessDetail(processId: string, processType: number) {
    return urlServices + ProcessType.urlString[processType] + "/" + processId;
}

// INSCRIPTIONS
export function urlNewInscriptionId(applicantData?: any) {
    return applicantData ? urlServices + "inscriptions/representative" : urlServices + "inscriptions";
}
export function urlInscriptionInProcess(inscriptionId: string) {
    return urlServices + "inscriptions/" + inscriptionId + "/finish";
}
export function urlInscriptionAttachMeritsFiles(nif: string, inscriptionId: string) {
    return urlServices + "inscriptions/" + nif + "/inscriptions/" + inscriptionId + "/attachMerits/files";
}
export function urlInscriptionAttachMerits(nif: string, inscription: Inscription) {    
    return urlServices + "inscriptions/" + nif + "/inscriptions/" + inscription.id + "/attachMerits";
}
export function urlInscriptionInProcessOnlinePayment(inscriptionId: string) {
    return urlServices + "inscriptions/" + inscriptionId + "/onlinePayment";
}
export function urlInscriptionInProcessAutoSave(inscriptionId: string) {
    return urlServices + "inscriptions/" + inscriptionId;
}
export function urlInscriptionById(inscriptionId: string) {
    return urlServices + "inscriptions/" + inscriptionId;
}
export function urlAddFileToInscription(inscriptionId: string) {
    return urlServices + "inscriptions/" + inscriptionId + "/files";
}
export function urlRemoveFileFromInscription(inscriptionId: string, fileId: string) {
    return urlServices + "inscriptions/" + inscriptionId + "/files/" + fileId;
}
export function urlInscriptionInProcessInfo(inscriptionId: string) {
    return urlServices + "inscriptions/" + inscriptionId + "/process";
}
export function urlInscriptionMerits(inscriptionId: string) {
    return urlServices + "inscriptions/" + inscriptionId + "/merits";
}
export function urlInscriptionExams(inscriptionId: string) {
    return urlServices + "inscriptions/" + inscriptionId + "/evaluableExams";
}
export function inscriptionJustifyUrl(inscriptionId: string) {
    return urlServices + "inscriptions/" + inscriptionId + "/justify";
}
export function getInscriptionPrev(processId: string) {
    return urlServices + "processes/" + processId + "/inscriptions/preview";
}
export function urlPaymentRedsysData(inscriptionId: string, paymentOrder: string) {
    return urlServices + "payment/" + inscriptionId + "/order/" + paymentOrder;
}
export function urlPaymentGttCaixaData(inscriptionId: string, paymentOrder: string) {
    return urlServices + "paymentstc/" + inscriptionId + "/order/" + paymentOrder;
}

// ENTITY
export function urlEntity(entityId: string) {
    return urlServices + "EntityOep/" + entityId;
}
export function urlEntities() {
    return urlServices + "EntityOep";
}

// MASTERDATA
export function masterDataProvinces() {
    return urlServices + "masterdata/provinces";
}
export function masterDataStreetTypes() {
    return urlServices + "masterdata/streetTypes";
}
export function masterDataCountries() {
    return urlServices + "masterdata/countries";
}
export function masterDataAutonomousCommunity() {
    return urlServices + "masterdata/autonomousCommunities";
}
export function masterDataHeaderConfiguration() {
    return urlServices + "masterdata/headerConfiguration";
}
export function masterDataClientInfo() {
    return urlServices + "masterdata/clientInfo";
}
export function masterDataNationalities() {
    return urlServices + "masterdata/nationalities";
}
export function masterDataMunicipalities(provinceId) {
    return urlServices + "masterdata/provinces/" + provinceId + "/municipalities";
}
export function masterDataLanguajes() {
    return urlServices + "masterdata/languages";
}

export function masterDataFlags() {
    return urlServices + "masterdata/featureFlags";
}

export function processesConfigurationPayExemptionList() {
    return urlServices + "masterdata/processConfigurationPayExemption";
}

export function processesConfigurationAuthorizationList() {
    return urlServices + "masterdata/processConfigurationAuthorizationEntities";
}

export function processesConfigurationGroup() {
    return urlServices + "masterdata/processConfigurationGroup";
}

export function clientProviders() {
    return urlServices + 'masterdata/providers';
}


// MY INSCRIPTIONS
export function urlInscriptionsByDni(dni: string) {
    return urlServices + "myInscriptions/" + dni;
}
export function urlRejectedRequirements(nif: string, inscriptionId: string) {
    return urlServices + "myInscriptions/" + nif + "/inscriptions/" + inscriptionId + "/rejectedEvaluableConditions"
}
export function urlSendToPending(nif: string, inscriptionId: string) {
    return urlServices + "myInscriptions/" + nif + "/inscriptions/" + inscriptionId + "/rejectedEvaluableConditions"
}
export function urlAddFileToRejectedRequirements(nif: string, inscriptionId: string) {
    return urlServices + "myInscriptions/" + nif + "/inscriptions/" + inscriptionId + "/rejectedEvaluableConditions/files";
}
export function urlRemoveFileFromRejectedRequirements(nif: string, inscriptionId: string, fileId: string) {
    return urlServices + "myInscriptions/" + nif + "/inscriptions/" + inscriptionId + "/rejectedEvaluableConditions/files/" + fileId;
}
export function urlInscriptionDniAndId(nif: string, inscriptionId: string) {
    return urlServices + "myInscriptions/" + nif + "/inscriptions/" + inscriptionId;
}
export function urlProcessInscriptionsData(nif: string, inscriptionId: string) {
    return urlServices + "myInscriptions/" + nif + "/inscriptions/" + inscriptionId + '/process';
}
export function urlEditInscriptionAddress(nif: string, address: Address) {
    return urlServices + "myInscriptions/" + nif + "/inscriptions/" + address.inscriptionId + '/address';
}
export function urlEditInscriptionNotification(nif: string, contactData: ContactData) {
    return urlServices + "myInscriptions/" + nif + "/inscriptions/" + contactData.inscriptionId + '/contactData';
}
export function urlEditInscriptionRepresentativeAddress(nif: string, address: Address) {
    return urlServices + "myInscriptions/" + nif + "/inscriptions/" + address.inscriptionId + '/representativeaddress';
}
export function urlEditInscriptionRepresentativeNotification(nif: string, inscriptionId: string) {
    return urlServices + "myInscriptions/" + nif + "/inscriptions/" + inscriptionId + '/representativecontactData';
}
export function urlIncidentsMeritsInfo(nif: string, inscriptionId: string) {
    return urlServices + "myInscriptions/" + nif + "/inscriptions/" + inscriptionId + "/meritAppeal"
}

// PUBLICATIONS
export function urlLastPublications(amount: number) {
    return urlServices + "publications/" + amount;
}
// ORDEREDBY
export function orderedBy(param: string, desc: boolean = false): string {
    return param ? `?orderedBy=${param}&desc=${desc}` : '';
}

// REPLACEMENT
export function urlReplacementLists(): string {
    return urlServices + "ReplacementLists";
}

export function urlReplacementDetail(replacementId: string) {
    return urlServices + "ReplacementLists/" + replacementId + '/Detail';
}

export function urlReplacementJobProposal(replacementId: string) {
    return urlServices + "ReplacementLists/" + replacementId + '/massiveOffers/public';
}

export function urlReplacementResign(token: string) {
    return urlServices + "ReplacementLists/jobofferresponse/resign/" + token;
}

export function urlReplacementName(replacementId: string) {
    return urlServices + "ReplacementLists/" + replacementId + '/name';
}
// IP
export function urlGetIP() {
    return urlServices + "Identity/ip";
}
