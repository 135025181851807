//LIBRERIES
import { computed, createApp, defineComponent, onMounted, Ref, ref, nextTick, reactive, toRefs } from 'vue'
import { i18n } from '../lang/index';
import * as input from "../node_modules/saviafront/lib/js/compiled/md-input-module";
import * as select from "../node_modules/saviafront/lib/js/compiled/md-select-module.js";
import { useI18n } from 'vue-i18n';
import orderBy from "lodash/orderBy";
import deburr from "lodash/deburr";
import moment from "../node_modules/moment/moment";

//MODELS
import { App } from "../domain/App";
import { Process } from "../domain/entities/process/Process";
import { Entity } from "../domain/Entity";
import { Publication } from "../domain/Publication";
import { Cookie } from "../domain/Cookie";
import { ProcessStatus } from "../domain/enum/ProcessStatus";
import { ProcessPhases } from "../domain/enum/ProcessPhases";
import { ProcessType } from "../domain/enum/ProcessType";
import { OppositionType } from "../domain/enum/OppositionType";
import { Languages } from "../domain/enum/Languages";
import { Constants } from "../domain/enum/Constants";
import { Filter } from "../domain/entities/filter/Filter";
import { Filters } from '../domain/entities/filter/IndexFilters';
import { FilterTypes } from "../domain/enum/FilterTypes";
import { Representative } from "../domain/Representative";
import ICommonDataState from '../domain/interfaces/ICommonDataState';

//APISERVICES
import { getProcessList, getEntityById, getMainFileById } from "../infrastructure/apiServices/process.service";
import { getLastPublicationsList } from "../infrastructure/apiServices/publications.service";
import * as InputService from "./services/InputService";
import * as MunicipalitiesService from "./services/MunicipalitiesService";
import { getMasterDataCountries, getMasterDataStreetTypes, getMasterDataProvinces, getMasterDataAutonomousCommunity, getMasterDataMunicipalities, getProcessesConfigurationGroup, getHeaderConfiguration, getClientAvailableLanguajes, getFeatureFlag } from "../infrastructure/apiServices/masterData.service";
import * as OrderSelect from "./services/OrderSelect";

// Repositories
import { RepresentativeRepository } from '../infrastructure/repositories/RepresentativeRepository';

//COMPONENTS
import SdFileImporter from "./components/SdFileImporter/SdFileImporter.vue";
import SdFilter from "./components/SdFilter/SdFilter.vue";

//COMPOSABLES
import { useRepresentativeForm } from "./composables/useRepresentativeForm";
import { useMasterDataAddress } from "./composables/useMasterDataAddress"; 
import { useCommonData } from "./composables/useCommonData";


export const IndexComponent = defineComponent({
  components: { SdFileImporter, SdFilter },
  setup() {
    // dependencies
    const { t, locale } = useI18n();

    // states
    const commonDataState = reactive({
      headerConfig: {},
      availableLanguajes: { defaultLanguage: '', secondLanguage: ''},
      cookie: new Cookie(),
      app: new App(),
      locale
    });
    
    // computed properties
    const pageTitle = computed(() => t(ProcessType.title[processTypeSelected]));
    const currentLanguage = computed(() => {
      refreshStreeTypes(representativeForm);
      return commonDataState.locale;
    });
    const lastLogin = computed(() => moment(commonDataState.app.profile.lastLogin[0]).format(commonDataState.app.formatDate) + ' ' + moment.utc(commonDataState.app.profile.lastLogin[0]).local().format('HH:mm:ss'));
    const noNif = computed(() => t("lang.inscriptions.noNif"));
    const orderedProcess = computed(() => filter.value.filteredData(commonDataState.locale));

    // shared properties
    const imageClass = commonDataState.app.params.get('type') ? ProcessType.image[Number(commonDataState.app.params.get('type'))] : ProcessType.image[ProcessType.enum.CALL];
    const isLoading = ref(false);
    const isLoadingProcesses = ref(false);
    const showMandatoryCookies = ref(false);
    const showAnalysisCookies = ref(false);
    const showModalConfigurateCookies = ref(false);
    const showServiceStopModal = ref(false);
    const dontShowAgainStopServiceModalAgain = ref(false);
    const serviceStopLocalStorageKey = 'dontShowServiceStopModal';
    const serviceStopEndDate = moment('2025-02-19T18:30:00');
    const lastPublications = ref<Publication[]>([]);

    // view properties
    const processTypeSelected = commonDataState.app.params.get('type') ? Number(commonDataState.app.params.get('type')) : 0;
    const filter = ref(new Filter());
    const filters = Filters;
    const processList: Ref<Process[]> = ref([]);
    const processSelectedId = ref('');
    const showToaster = ref(false);
    const modalVisible = ref(false);
    const inscriptionFile = ref('');
    const urlToInscription = ref('');
    const loadingStreetTypes = ref(false);
    const municipalities = ref([]);

    // methods
    const getAllData = () => {
      getMasterDataAddress();
      getData();
      getAsideData();
      commonDataState.app.getProfile();
    };

    const getAsideData = () => {
      isLoading.value = true;
      getLastPublicationsList(Constants.publications.AMOUNT_OF_PUBLICATIONS).then((response: any) => {
        response.data.forEach(element => {
          lastPublications.value.push(new Publication(element));
        });
        getLastPublicationsListOrdered();
        isLoading.value = false;
      }).catch(() => {
        isLoading.value = false;
      });
    };

    const saveCookies = () => {
      commonDataState.cookie.savePersonalizateCookies();
      showModalConfigurateCookies.value = false;
    };

    const closeModalConfigurateCookies = () => {
      showModalConfigurateCookies.value = false;
      showMandatoryCookies.value = false;
      showAnalysisCookies.value = false;
    };

    const acceptAllCookies = () => {
      commonDataState.cookie.acceptAllCookies();
    };

    const declineAllCookies = () => {
      commonDataState.cookie.declineAllCookies();
    };

    const closeStopServiceModal = () => {
      showServiceStopModal.value = false;
    };

    const acceptStopServiceModal = () => {
      if (dontShowAgainStopServiceModalAgain.value) {
        const serviceStopData = { serviceStopNotificationReaded: dontShowAgainStopServiceModalAgain.value, expires: serviceStopEndDate };
        localStorage.setItem(serviceStopLocalStorageKey, JSON.stringify(serviceStopData));
      }
      showServiceStopModal.value = false;
    };

    const checkModalServiceStopLocalStorage = () => {
      const serviceStopItem = localStorage.getItem(serviceStopLocalStorageKey);
      if (!serviceStopItem) {
        return;
      }
      const serviceStopItemData = JSON.parse(serviceStopItem);
      if (moment() > moment(serviceStopItemData.expires)) {
        localStorage.removeItem(serviceStopLocalStorageKey);
      }
    };

    const getLastPublicationsListOrdered = () => {
      lastPublications.value = orderBy(lastPublications.value, ['date', lastPublication => deburr(lastPublication.title[commonDataState.locale]).toLowerCase()], ['desc', 'asc']);
    };

    const changeLocal = (language): void => {
      commonDataState.locale = language;
      commonDataState.app.changeLanguaje(language);
      getLastPublicationsListOrdered();
    };

    const getData = async () => {
      isLoadingProcesses.value = true;
      try {
        const [processesConfigResponse, processListResponse] = await Promise.all([
          getProcessesConfigurationGroup(),
          getProcessList(processTypeSelected)
        ]);

        // TO DO -> Tipar
        const processesConfigGroups = (processesConfigResponse as any).data;
        const processListData = (processListResponse as any).data;

        for (const element of processListData) {
          const process = new Process(element, processesConfigGroups);
          await gettingEntityById(element.entityOepId, process); // Ensure gettingEntityById is async
          processList.value.push(process);
        }

        processList.value = orderBy(
          processList.value,
          ['year', process => deburr(process.title[commonDataState.locale]).toLowerCase()],
          ['desc', 'asc']
        );

        filter.value.setData(processList.value);
        filter.value.setFilterElements(filters);
      } catch (error) {
        console.log(error);
      } finally {
        isLoadingProcesses.value = false;
      }
    };

    const gettingEntityById = async (id: string, process: Process) => {
      try {
        const response = (await getEntityById(id)) as { data: { id: string, name: string } };
        const responseData = response as { data: { id: string, name: string } };
        const entity = new Entity(responseData.data.id, responseData.data.name);
        process.entity = entity;
      } catch (error) {
        console.log(error);
      }
    };

    const buildUrlToInscription = (id: string) => {
      return getMainFileById(id);
    };

    const onlyNumber = ($event, strict = false) => {
      const keyCode = $event.which ? $event.which : $event.keyCode;
      if (
        (keyCode < Constants.keyCodes.ZERO_KEY_CODE || keyCode > Constants.keyCodes.NINE_KEY_CODE) && strict ||
        (keyCode < Constants.keyCodes.ZERO_KEY_CODE || keyCode > Constants.keyCodes.NINE_KEY_CODE) &&
        keyCode !== Constants.keyCodes.DOT_KEY_CODE &&
        keyCode !== Constants.keyCodes.COMMA_KEY_CODE
      ) {
        $event.preventDefault();
      }
    };

    const onSelectFocus = ($event) => {
      select.onSelectFocus($event.target);
    };

    const onSelectBlur = ($event) => {
      select.onSelectBlur($event.target);
    };

    const onInputFocus = ($event: any) => {
      input.onInputFocus($event.target);
    };

    const onInputBlur = ($event: any) => {
      const elementNode = $event.target;
      input.onInputBlur(elementNode);
      if (!elementNode.validity.valid) {
        return;
      }
      InputService.validateFormRequired(elementNode.parentNode);
    };

    const onInputFilter = (value) => {
      filter.value = value;
    };

    const validateForm = async () => {
      await nextTick();
      return InputService.validateFormRequired(representativeForm.value);
    };

    const goToInscription = async () => {
      if (!validateForm()) {
        return;
      }
      await storeRepresentative();
      location.href = 'inscription.html?id=' + processSelectedId.value + '&type=' + processTypeSelected + '&nif=' + applicantData.value.nif.toUpperCase() + '&firstSurname=' + applicantData.value.firstSurname + '&secondSurname=' + applicantData.value.secondSurname + '&name=' + applicantData.value.name;
    };

    const storeRepresentative = async () => {
      const representativeRepository = new RepresentativeRepository();
      try {
        await representativeRepository.saveRepresentative(representativeData.value as Representative);
      } catch (error) {
        console.error(error);
      }
    };
    
    const goToDetail = (id: string) => {
      location.href = "processDetail.html?id=" + id + '&type=' + processTypeSelected;
    };

    const openModal = (id: string) => {
      processSelectedId.value = id;
      urlToInscription.value = `inscription.html?id=${id}`;
      buildUrlToInscription(id)
        .then(function (response: any) {
          if (response.data) {
            inscriptionFile.value = commonDataState.app.requestTo + response.data.url + "?origin=" + commonDataState.app.origin;
          }
          modalVisible.value = true;
        }).catch((error) => {
          console.log(error);
        });
    };

    const closeModal = () => {
      modalVisible.value = false;
      inscriptionFile.value = '';
      urlToInscription.value = '';
      processSelectedId.value = '';
      setRepresentativeUseCaseToInitialState();
    };

    const updateCommonData = (state: ICommonDataState) => {
      commonDataState.availableLanguajes = state.availableLanguajes;
      commonDataState.cookie = state.cookie;
      commonDataState.headerConfig = state.headerConfig;
      commonDataState.app = state.app;
      commonDataState.locale = state.locale;

      getAllData();
    };

    // composables
    const useRepresentativeFormDependencies = {
      checkValueAndUpdateInput: InputService.checkValueAndUpdateInput,
      isValidNie: InputService.isValidNie,
      isValidNif: InputService.isValidNif,
      isValidMail: InputService.isValidMail,
      municipalities,
      getMasterDataMunicipalities,
      setMunicipalities: MunicipalitiesService.setMunicipalities,
    };
    const useMasterDataAddressDependencies = {
      orderSelect: OrderSelect,
      getMasterDataAutonomousCommunity,
      getMasterDataStreetTypes,
      getMasterDataCountries,
      getMasterDataProvinces,
      checkValueAndUpdateInput: InputService.checkValueAndUpdateInput,
    };
    const {
      applicantData,
      representativeData,
      representativeForm,
      representativeEmail,
      postCode,
      foreignPostCode,
      isRepresentative,
      setRepresentativeUseCaseToInitialState,
      onRepresentativeCountryChange,
      onRepresentativeProvinceChange,
      onInputRepresentativeAuthorizationFile,
      onRemoveRepresentativeAuthorizationFile,
      openRepresentativeForm,
      representativeDocuments,
      anyFieldRequiredEmpty,
      isApplicantNifValid,
      isRepresentativeFormInvalid
    } = useRepresentativeForm(useRepresentativeFormDependencies);
    const {
      countries,
      autonomousCommunityList,
      streetTypes,
      provinces,
      getMasterDataAddress,
      refreshStreeTypes,
    } = useMasterDataAddress(useMasterDataAddressDependencies);
    const useCommonDataDependencies = {
      updateCommonData,
      getHeaderConfiguration,
      getFeatureFlag,
      getClientAvailableLanguajes,
    };
    const { loadCommonData } = useCommonData(useCommonDataDependencies);

    // lifecycle hooks
    onMounted(() => {
      loadCommonData();
      checkModalServiceStopLocalStorage();
      showServiceStopModal.value = commonDataState.app.isServiceStopActive && (!localStorage.getItem(serviceStopLocalStorageKey));
      if (commonDataState.app.params.get('certificateLogout') === 'true') {
        setTimeout(() => {
          showToaster.value = false;
        }, 8000);
      };
    });

    return {
      // dependencies
      t,
      ProcessType,
      Languages,
      constants: Constants,
      // states
      ...toRefs(commonDataState),
      // shared view properties
      imageClass,
      isLoading,
      showMandatoryCookies,
      showAnalysisCookies,
      showModalConfigurateCookies,
      showServiceStopModal,
      dontShowAgainStopServiceModalAgain,
      serviceStopLocalStorageKey,
      serviceStopEndDate,
      lastPublications,
      representativeData,
      loadingStreetTypes,
      streetTypes,
      municipalities,
      countries,
      provinces,
      autonomousCommunityList,
      // computed properties
      pageTitle,
      currentLanguage,
      lastLogin,
      noNif,
      anyFieldRequiredEmpty,
      isApplicantNifValid,
      orderedProcess,
      representativeDocuments,
      isRepresentativeFormInvalid,
      // methods
      getAllData,
      getData,
      getAsideData,
      getLastPublicationsListOrdered,
      saveCookies,
      closeModalConfigurateCookies,
      acceptAllCookies,
      declineAllCookies,
      changeLocal,
      acceptStopServiceModal,
      closeStopServiceModal,
      goToDetail,
      openModal,
      openRepresentativeForm,
      goToInscription,
      closeModal,
      onInputFocus,
      onInputBlur,
      refreshStreeTypes,
      onRepresentativeCountryChange,
      onRepresentativeProvinceChange,
      onlyNumber,
      onSelectFocus,
      onSelectBlur,
      onInputRepresentativeAuthorizationFile,
      onRemoveRepresentativeAuthorizationFile,
      onInputFilter,
      // view properties
      modalVisible,
      inscriptionFile,
      urlToInscription,
      ProcessStatus,
      ProcessPhases,
      OppositionType,
      FilterTypes,
      processSelectedId,
      isRepresentative,
      applicantData,
      filter,
      filters,
      processTypeSelected,
      isLoadingProcesses,
      showToaster,
      processList,
      spainCode: Constants.country.SPAIN_CODE,
      fiveZeros: Constants.postalCode.FIVE_ZEROS,
      // refs
      representativeForm,
      postCode,
      foreignPostCode,
      representativeEmail
    };
  },
});

const vueApp = createApp(IndexComponent);
vueApp.use(i18n);
vueApp.config.compilerOptions.whitespace = 'preserve';
vueApp.mount(Constants.APP);
