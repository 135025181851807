import { Wizard } from "../../domain/entities/inscription/Wizard";

    export function validateErrorCollapsibleBox(form: HTMLFormElement) {
        const errorCollapsable = form.querySelectorAll('.collapsible-box.error');
        errorCollapsable.forEach((box: any) => {
            box.classList.remove('error');
        });
        const inputError = form.querySelectorAll('.md-input.form-element-validation.error');
        if (inputError) {
            inputError.forEach(el => {
                const box = el.closest('.collapsible-box');
                if (box) {
                    box.classList.add('error');
                }
            });
        }
    }

    export function validateFormRequired(form: HTMLFormElement, wizard?: Wizard): boolean {
        const inputs = form.querySelectorAll('input');
        inputs.forEach((input: any) => {
            const formElement = input.closest('.form-element-validation');
            if (formElement) {
                formElement.classList.remove('error');
            }
        });
        const errorInputs = form.querySelectorAll(':invalid, .invalid-dni, .invalid-exemption-type, .invalid-date, .invalid-references');
        errorInputs.forEach((input: any) => {
            const formElement = input.closest('.form-element-validation');
            if (formElement) {
                formElement.classList.add('error');
            }
            if (wizard) {
                const step = input.closest('.inscription-step');
                wizard.getStep(step.getAttribute('data-group'), step.getAttribute('data-step')).error = true;
            }
        });
        return !errorInputs.length;
    }

    export function validateFileRequired(form: HTMLFormElement | HTMLElement): boolean {
        let errorInputs;
        if (form) {
            const inputs = form.querySelectorAll('md-file-add__input');
            inputs.forEach((input: any) => {
                input.closest('.form-element-validation').classList.remove('error');
            });
            errorInputs = form.querySelectorAll(':invalid');
            errorInputs.forEach((input: any) => {
                input.closest('.form-element-validation').classList.add('error');
            });

        }
        return form && errorInputs ? !errorInputs.length : false;
    }

    export function checkValueAndUpdateInput(form: HTMLFormElement) {
        const errorInputs = form.querySelectorAll('.md-input__element, .md-select__element, .mx-input');
        errorInputs.forEach((input: any) => {
            if (input.value != "") {
                input.closest('.form-element-validation').classList.add("filled");
            }
        });
    }

    export function isValidNif(value: any) {
        if (!value) {
            return false;
        }
        value = value.toUpperCase();
        // Basic format test
        if (!value.match('((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)')) {
            return false;
        }
        // Test NIF
        if (/^[0-9]{8}[A-Z]{1}$/.test(value)) {
            return ('TRWAGMYFPDXBNJZSQVHLCKE'.charAt(value.substring(8, 0) % 23) === value.charAt(8));
        }
        // Test specials NIF (starts with K, L or M)
        if (/^[KLM]{1}/.test(value)) {
            return (value[8] === String.fromCharCode(64));
        }
        return false;
    }

    export function isValidNie(value: any) {
        if (!value) {
            return false;
        }
        value = value.toUpperCase();
        // Basic format test
        if (!value.match('((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)')) {
            return false;
        }
        // Test NIE
        // T
        if (/^[T]{1}/.test(value)) {
            return (value[8] === /^[T]{1}[A-Z0-9]{8}$/.test(value));
        }
        // XYZ
        if (/^[XYZ]{1}/.test(value)) {
            return (
                value[8] === 'TRWAGMYFPDXBNJZSQVHLCKE'.charAt(
                    value.replace('X', '0')
                        .replace('Y', '1')
                        .replace('Z', '2')
                        .substring(0, 8) % 23
                )
            );
        }
        return false;
    }

    export function isValidMail(value: any): boolean {
        // Test Mail (can contain uppercase, lowercase, numbers, and special characters before the @
        //            after the @ can contain uppercase, lowercase, numbers, and hyphens followed by a dot
        //            after the dot can contain uppercase, lowercase, numbers, and hyphens and dots)
        const regexp = /^[^@\s]+@[^@\s]+\.[a-zA-Z0-9]{2,}$/;
        return regexp.test(value);
    }
    