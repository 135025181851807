import * as SecurityService from "../application/services/SecurityService";
import axios from "axios";
import moment from "../node_modules/moment/moment";
import { Browser } from './Browser';
import { Constants } from "./enum/Constants";

var mgr = new SecurityService.default();

export class App {
    private _location: string;
    private _pathname: string;
    private _state: string;
    private _host: string;
    private _origin: string;
    private _params: URLSearchParams;
    private _requestTo: string;
    private _displayMobileMenu: boolean;
    private _logged: boolean;
    private _local: string;
    private _profile: any;
    private _featureFlags: any;
    private _isLanguajeLoad: boolean;
    private _formatDate: string;
    private _myBrowserService: Browser;
    private _isServiceStopActive: boolean;
    private _serviceStopStartHour: string;
    private _serviceStopEndHour : string;
    private _headerLangs: string[];

    constructor() {
        this._location = document.location.href;
        this._pathname = document.location.pathname;
        this._host = document.location.host;
        this._origin = document.location.origin;
        this._state = this.getState();
        this._requestTo = 'http://localhost:8082/';
        this._params = new URLSearchParams(window.location.search);
        this._displayMobileMenu = false;
        this._logged = false;
        this._profile = null;
        this._featureFlags = {};
        this._isLanguajeLoad = false;
        this._formatDate = '';
        this._myBrowserService = new Browser();
        this._isServiceStopActive = moment() <= moment('2025-02-19T16:30:00');
        this._serviceStopStartHour = moment('2025-02-19T16:30:00').format('HH:mm');
        this._serviceStopEndHour = moment('2025-02-19T18:30:00').format('HH:mm');
        this._headerLangs = [];
    }

    public get location(): string {
        return this._location;
    }

    public get featureFlags(): any {
        return this._featureFlags;
    }

    public get pathname(): string {
        return this._pathname;
    }

    public get state(): string {
        return this._state;
    }

    public get host(): string {
        return this._host;
    }

    public get params(): URLSearchParams {
        return this._params;
    }

    public get origin(): string {
        return this._origin;
    }

    public get requestTo(): string {
        return this._requestTo;
    }

    public get displayMobileMenu(): boolean {
        return this._displayMobileMenu;
    }

    public get profile(): any {
        return this._profile;
    }

    public get isLanguajeLoad() {
        return this._isLanguajeLoad;
    }

    public get formatDate() {
        return this._formatDate;
    }

    public get headerLangs(): string[] {
        return this._headerLangs;
    }

    public get myBrowserService() {
        return this._myBrowserService;
    }

    public get isServiceStopActive() {
        return this._isServiceStopActive;
    }

    public set isLanguajeLoad(isLanguajeLoad: any) {
        this._isLanguajeLoad = isLanguajeLoad;
    }
    
    public set profile(profile: any) {
        this._profile = profile;
    }
    
    public set formatDate(formatDate: string) {
        this._formatDate = formatDate;
    }

    public get serviceStopStartHour() {
        return this._serviceStopStartHour;
    }

    public set serviceStopStartHour(serviceStopStartHour: string) {
        this._serviceStopStartHour = serviceStopStartHour;
    }

    public get serviceStopEndHour() {  
        return this._serviceStopEndHour;
    }

    public set serviceStopEndHour(serviceStopEndHour: string) {
        this._serviceStopEndHour = serviceStopEndHour;
    }

    public set isServiceStopActive(isServiceStopActive: boolean) {
        this._isServiceStopActive = isServiceStopActive;
    }

    public set headerLangs(langs: string[]) {
        this._headerLangs = langs;
    }

    public logOut() {
        mgr.signOut();
    }

    public login() {
        localStorage.setItem("urlAfterLogin", this.location);
        mgr.signIn();
    }

    public getProfile() {
        let self = this;
        return new Promise((resolve, reject) => {
            mgr.getProfile().then(profile => {
                self._profile = profile;
                return resolve(true);
            }).catch((error) => {
                console.error(error);
                reject(false);
            });
        });
    }
    
    public toogleMobileMenu(): void {
        this._displayMobileMenu = !this._displayMobileMenu;
    }

    private getState(): string {
        let hasQueryString = this._location.indexOf('?') !== -1;
        let cleanedQuery = hasQueryString ? this._location.slice(0, this._location.indexOf('?')) : this._location;
        let parts = cleanedQuery.split('/');
        let lastPart = parts[parts.length - 1];
        return lastPart.split('.')[0];
    }

    public changeLanguaje(lang) {
        window.localStorage.setItem('local', lang);
        axios.defaults.headers.common['Accept-Language'] = lang;
        moment.localeData(lang);
        this.formatDate = (moment().locale(lang).localeData() as any)._longDateFormat[Constants.format.L].replace(/-/g, '/');
    }

    public getLanguaje(defaultLanguage: string) {
        const localUrl = new URLSearchParams(window.location.search);
        const languajeActive = localUrl.get('lang') != null ? localUrl.get('lang') : window.localStorage.getItem('local') !== null ? window.localStorage.getItem('local') : defaultLanguage;
        axios.defaults.headers.common['Accept-Language'] = languajeActive;
        moment.localeData(languajeActive);
        this.formatDate = (moment().locale(languajeActive).localeData() as any)._longDateFormat[Constants.format.L].replace(/-/g, '/');
        return languajeActive;
    }

    public toServerCryptoSignature() {
        return {
            authenticationDate: this.profile.authenticationDate,
            name: this.profile.given_name,
            firstSurname: this.profile.name,
            secondSurname: this.profile.family_name,
            nif: this.profile.nickname,
            certificateType: this.profile.certificateType,
            securityLevel: this.profile.securityLevel,
            identityProvider: this.profile.idp,
            authenticationSystem: this.profile.amr && this.profile.amr.length > 0 ? this.profile.amr[0] : '' ,
            ip: this.profile.ip,
            sessionId: this.profile.sid
        }
    }
}