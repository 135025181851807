import { Entity } from "../../Entity";
import { OepDocument } from "../../OepDocument";
import moment from "../../../node_modules/moment/moment";
import { MultiLanguage } from "../../MultiLanguage";
import { Vacancies } from "./Vacancies";
import { Turns } from "./Turns";
import { ICallReference } from "../../interfaces/IReference";


export class Process {
    private _id: string;
    private _entity: Entity;
    private _title: MultiLanguage;
    private _description: MultiLanguage;
    private _group: number;
    private _startDate: moment.Moment | null;
    private _endDate: moment.Moment| null;
    private _status: number;
    private _oppositionType: number;
    private _staffType: number;
    private _vacancies: Vacancies | null;
    private _turns: Turns | null;
    private _documents: OepDocument[];
    private _inscriptionFile: string;
    private _isTimeActive: boolean;
    private _claimsStartDate: moment.Moment;
    private _claimsEndDate: moment.Moment;
    private _claimsPeriodOpen: boolean;
    private _type: number;
    private _isRanked: boolean;
    private _autoScaling: boolean;
    private _phase: number;
    private _year: number;
    private _allowPostInscriptionMeritPresentation: boolean;
    private _meritPresentationStartDate: moment.Moment | null;
    private _meritPresentationEndDate: moment.Moment | null;
    private _isReferenceCall: boolean;
    private _references: ICallReference[];

    constructor(element: any, processConfigurationGroup?: any) {
        this._id = element.id;
        this._entity = new Entity();
        this._title = new MultiLanguage(element.title);
        this._description = new MultiLanguage(element.description);
        this._group = processConfigurationGroup ? processConfigurationGroup.filter(processConfig => processConfig.groupType === element.group)[0] : undefined;
        this._startDate = element.startDate ? moment(element.startDate) : null;
        this._endDate = element.endDate ? moment(element.endDate) : null;
        this._status = element.status;
        this._oppositionType = element.oppositionType;
        this._staffType = element.staffType;
        this._vacancies = element.vacancies ? new Vacancies(element.vacancies) : null;
        this._turns = element.turns ? new Turns(element.turns) : null;
        this._documents = [];
        this._isTimeActive = moment().isBetween(this._startDate, this._endDate, "day", "[]");
        this._claimsStartDate = element.claimsStartDate ? moment(element.claimsStartDate) : null;
        this._claimsEndDate = element.claimsEndDate ? moment(element.claimsEndDate) : null;
        this._claimsPeriodOpen = moment().isBetween(this._claimsStartDate, this._claimsEndDate, "day", "[]");
        this._type = element.type;
        this._isRanked = element.rankCreated;
        this._autoScaling = element.autoScaling;
        this._phase = element.phase;
        this._year = element.year;
        this._allowPostInscriptionMeritPresentation = element.allowPostInscriptionMeritPresentation || false;
        this._meritPresentationStartDate = element.meritPresentationStartDate ? moment.utc(element.meritPresentationStartDate) : null;
        this._meritPresentationEndDate = element.meritPresentationEndDate ? moment.utc(element.meritPresentationEndDate) : null;
        this._isReferenceCall = element.isReferenceCall;
        this._references = element.isReferenceCall && element.references.length > 0 ? element.references : [];
    }

    addDocuments(document: OepDocument){
        this._documents.push(document);
    }

    public set id(id: string) {
        this._id = id;
    }

    public set entity(entity: Entity) {
        this._entity = entity;
    }

    public set group(group: number) {
        this._group = group;
    }

    public set startDate(startDate: moment.Moment) {
        this._startDate = startDate;
    }

    public set endDate(endDate: moment.Moment) {
        this._endDate = endDate;
    }

    public set status(status: number) {
        this._status = status;
    }

    public set oppositionType(oppositionType: number) {
        this._oppositionType = oppositionType;
    }

    public set staffType(staffType: number) {
        this._staffType = staffType;
    }

    public set inscriptionFile(inscriptionFile: string) {
        this._inscriptionFile = inscriptionFile;
    }

    public set claimsStartDate(claimsStartDate: moment.Moment) {
        this._claimsStartDate = claimsStartDate;
    }

    public set claimsEndDate(claimsEndDate: moment.Moment) {
        this._claimsEndDate = claimsEndDate;
    }

    public set type(type: number) {
        this._type = type;
    }

    public set phase(phase: number) {
        this._phase = phase;
    }

    public set references(references: ICallReference[]) {
        this._references = references;
    }

    public set isReferenceCall(isReferenceCall: boolean) {
        this._isReferenceCall = isReferenceCall;
    }

    public get id() {
        return this._id;
    }

    public get entity() {
        return this._entity;
    }

    public get title() {
        return this._title;
    }

    public get description() {
        return this._description;
    }

    public get group() {
        return this._group;
    }

    public get startDate() {
        return this._startDate;
    }

    public get endDate() {
        return this._endDate;
    }

    public get status() {
        return this._status;
    }

    public get oppositionType() {
        return this._oppositionType;
    }

    public get staffType() {
        return this._staffType;
    }

    public get vacancies() {
        return this._vacancies;
    }
    
    public get turns() {
        return this._turns;
    }

    public get documents(){
        return this._documents;
    }

    public get inscriptionFile(){
        return this._inscriptionFile;
    }

    public get isTimeActive(){
        return this._isTimeActive;
    }
    
    public get claimsStartDate() {
        return this._claimsStartDate
    }

    public get claimsEndDate() {
        return this._claimsEndDate;
    }

    public get claimsPeriodOpen(){
        return this._claimsPeriodOpen;
    }

    public get type(){
        return this._type;
    }

    public get isRanked(){
        return this._isRanked;
    }

    public get autoScaling(){
        return this._autoScaling;
    }
    public get phase(){
        return this._phase;
    }
    public get year(){
        return this._year;
    }
    
    public get allowPostInscriptionMeritPresentation() {
        return this._allowPostInscriptionMeritPresentation;
    }

    public get meritPresentationStartDate() {
        return this._meritPresentationStartDate;
    }

    public get meritPresentationEndDate() {
        return this._meritPresentationEndDate;
    }

    public get references() {
        return this._references;
    }

    public get isReferenceCall() {
        return this._isReferenceCall;
    }

    public get totalVacancies() {
        return this.isReferenceCall && this.references.length > 0 
            ? this.references
                .map(ref => ref.totalVacancies)
                .reduce((acc, value) => acc + value, 0)
            : this.vacancies.getTotalVacanciesAndExtra();
    }
}
