import { Filter } from '../../../domain/entities/filter/Filter';
import { FilterElement } from '../../../domain/entities/filter/FilterElement';
import { FilterTypes } from '../../../domain/enum/FilterTypes';
import { computed, defineComponent, ref } from 'vue';
import _ from 'lodash';

export default defineComponent({
    name: 'SdFilter',
    props: {
        value: { type: Filter, required: true }
    },
    emits: ['input'],
    setup(props, { emit }) {
        const filterTypes = FilterTypes;
        const filterCopy = ref(null);

        const filteredOptions = computed(() => filterCopy.value?.filterElements.filter(element => element.type === filterTypes.enum.CHECKBOX));

        const createCopyValue = () => {
            filterCopy.value = _.cloneDeep(props.value);
        }

        const setFilter = (filter: FilterElement) => {
            filter.isDropped = false;
            emitUpdate();
        }

        const emitUpdate = () => {
            emit('input', _.cloneDeep(filterCopy.value));
        }

        const removeFilter = (filter: FilterElement) => {
            filter.reset();
            emitUpdate();
        }

        return {
            filterTypes,
            filterCopy,
            filteredOptions,
            createCopyValue,
            setFilter,
            emitUpdate,
            removeFilter
        }
    },
    created() {
        this.createCopyValue();
    }
})
